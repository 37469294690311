<template>
  <v-card>
    <!-- Wait for etablissement to be populated, ie any field is not undefied -->
    <div
      v-if="etablissement.cl"
      class="pa-5 mb-5"
    >
      <v-card-title
        primary-title
        class="title"
      >
        Établissement scolaire
      </v-card-title>

      <table>
        <tr>
          <td> Nom </td>
          <td class="px-2" />
          <td> {{ etablissement.nom }} </td>
        </tr>
        <tr>
          <td> Code UAI </td>
          <td class="px-2" />
          <td> {{ etablissement.code_uai }} </td>
        </tr>
        <tr>
          <td> Capacité d'accueil </td>
          <td class="px-2" />
          <td> {{ etablissement.cap_accueil }} </td>
        </tr>
        <tr>
          <td> Conseil local </td>
          <td class="px-2" />
          <td> {{ etablissement.cl.code }} - {{ etablissement.cl.nom }} </td>
        </tr>
        <tr>
          <td> Niveau d'enseignement </td>
          <td class="px-2" />
          <td> {{ concatNiveauxEnseignement }} </td>
        </tr>
        <tr>
          <td> Établissement principal </td>
          <td class="px-2" />
          <td> {{ etablissement.is_etablissement_principal ? 'oui' : 'non' }} </td>
        </tr>
      </table>
      <v-card-title
        primary-title
        class="title mt-5"
      >
        Adresse
      </v-card-title>
      <table>
        <tr>
          <td> {{ etablissement.adresse.ligne1 }} </td>
        </tr>
        <tr>
          <td> {{ etablissement.adresse.ligne2 }} </td>
        </tr>
        <tr>
          <td> {{ etablissement.adresse.commune.code_postal }} </td>
        </tr>
        <tr>
          <td> {{ etablissement.adresse.commune.ville }} </td>
        </tr>
      </table>
      <v-card-title
        primary-title
        class="title mt-5"
      >
        Contact
      </v-card-title>
      <table>
        <tr>
          <td> Téléphone fixe </td>
          <td class="px-2" />
          <td> {{ etablissement.contact.telephone_fixe }} </td>
        </tr>
        <tr>
          <td> Téléphone portable </td>
          <td class="px-2" />
          <td> {{ etablissement.contact.telephone_mobile }} </td>
        </tr>
        <tr>
          <td> Email </td>
          <td class="px-2" />
          <td> {{ etablissement.contact.email }} </td>
        </tr>
        <tr>
          <td> Abonné Newsletter </td>
          <td class="px-2" />
          <td> {{ etablissement.contact.abonne_newsletter ? 'oui' : 'non' }} </td>
        </tr>
      </table>
      <v-card-title
        primary-title
        class="title mt-5"
      >
        Commentaire
      </v-card-title>
      <v-textarea
        v-model.trim="etablissement.commentaire"
        :readonly="true"
        class="elevation-1 my-2 px-2"
      />
      <v-card-title
        primary-title
        class="title mt-5"
      >
        Délégués
      </v-card-title>
      <v-data-table
        class="elevation-3"
        :items="etablissement.delegues"
        :headers="niveauxEnseignementHeaders"
        hide-default-footer
        disable-pagination
        no-data-text="Aucun délégué d'établissement"
      />
      <v-btn
        class="my-5 mr-5"
        color=""
        :to="{ name: 'recherche-etablissement' }"
        v-text="'Retourner à la liste'"
      />
      <v-btn
        v-if="canEdit"
        class="my-5"
        color="primary"
        :to="{ name: 'edition-etablissement', params: { etablissementId: etablissementId }}"
        v-text="'Modifier'"
      />
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import droits from '../../auth/droits.js';

export default {
  name: 'ViewEtablissementScolaireComponent',
  data() {
    return {
      etablissementId: null,
      niveauxEnseignementHeaders: [
        { text: 'Nom'     , value: 'personne.nom'              , sortable: true },
        { text: 'Prenom'  , value: 'personne.prenom'           , sortable: true },
        { text: 'Fonction', value: 'personne.type_fonction.nom', sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters('etablissementScolaire', {
      etablissement: 'getEtablissementActif',
    }),
    ...mapGetters('session', {
      getDroits: 'getDroits',
    }),
    canEdit() {
      return this.getDroits.includes(droits.ETABLISSEMENT_MODIFICATION);
    },
    concatNiveauxEnseignement() {
      let res = '';
      let ln = this.etablissement.niveaux_enseignement.length;
      for (let i = 0; i < ln; i++) {
        res += this.etablissement.niveaux_enseignement[i].libelle;
        if (i + 1 < ln) res += ', ';
      }
      return res;
    },
  },
  created() {
    this.etablissementId = this.$route.params.etablissementId;
    this.loadEtablissement({ etablissementId: this.etablissementId });
  },
  methods: {
    ...mapActions('etablissementScolaire', {
      loadEtablissement: 'loadEtablissementActif',
    }),
  },
};
</script>