<template>
  <div>
    <ViewEtablissementScolaireComponent />
  </div>
</template>

<script>
import ViewEtablissementScolaireComponent from '../../components/etablissementScolaire/ViewEtablissementScolaireComponent';

export default {
  name: 'ViewEtablissementScolaireContainer',
  components: {
    ViewEtablissementScolaireComponent,
  },
  data() {
    return {

    };
  },
};
</script>